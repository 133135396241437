import React, { useEffect, useState } from "react";
import Main from "./companents/Main/Main";
import Head from "./companents/Header/Head";
import Header from "./companents/Header/Header";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Luxury from "./pages/Luxury/Luxury";
import Contacts from "./pages/RouterApp/Contacts";
import AllDetail from "./pages/Detail/AllDetail";
import { api, get } from "./api/api";
import HeaderDerail from "./pages/Detail/HeaderDerail";
import SubCat from "./pages/Detail/SubCat";
import Us from "./pages/RouterApp/Us";
import TabsSeach from "./pages/Post/TabsSeach";
import Footer from "./companents/Section/Footer";

const App = () => {
  const [residentList, setResidentList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [popularList, setPopularList] = useState([]);
  const [banner, setBanner] = useState([]);
  const [header, setHeader] = useState([]);
  let lang = localStorage.getItem("lang");
  if (!lang) {
    localStorage.setItem("lang", "ru");
    lang = "ru";
  }
  const location = useLocation();

  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    handleScroll();
  }, [location]);

  useEffect(() => {
    if (lang) {
      api.get(`/${lang}/list/status`).then((response) => {
        setResidentList(response.data);
      });
    }
  }, [lang]);

  useEffect(() => {
    api.get(`/baner`).then((response) => {
      setBanner(response.data);
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const residents = await get.getHeader();
      setHeader(residents);
    };
    fetchData();
  }, []);

  return (
    <div>
      <Header header={header} banner={banner} />
      <Head
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        header={header}
      />
      <Routes>
        <Route path="/" element={<Navigate to={`/${lang}`} />} />
        <Route
          path={`/${lang}`}
          element={
            <Main
              residentList={residentList}
              searchInput={searchInput}
              popularList={popularList}
              banner={banner}
            />
          }
        />
        <Route
          path="/:lang/luxury-holiday"
          element={<Luxury residentList={residentList} />}
        />
        <Route
          path="/:lang/news/:slug"
          element={
            <AllDetail
              searchInput={searchInput}
              residentList={residentList}
              setSearchInput={setSearchInput}
            />
          }
        />
        <Route path="/:lang/contacts" element={<Contacts />} />
        <Route
          path="/:lang/category/:slug"
          element={
            <HeaderDerail
              residentList={residentList}
              searchInput={searchInput}
            />
          }
        />
        <Route
          path="/:lang/categories/:slug"
          element={<SubCat searchInput={searchInput} />}
        />
        <Route path="/:lang/about-us" element={<Us />} />
        <Route
          path="/:lang/tags/:name"
          element={
            <TabsSeach residentList={residentList} searchInput={searchInput} />
          }
        />
        {/* <Route path="*" element={<Navigate to={`/${lang}`} />} /> */}
      </Routes>
      <Footer residentList={residentList} />
    </div>
  );
};

export default App;
